import { Children, useEffect } from "react";
import { useParams } from "react-router";
import PageTemplate from "../../Common/PageTemplate";
import studentsActions from "../../../store/masterData/students/actions";
import loadingActions from "../../../store/loading/actions";
import { connect } from "react-redux";
import Accordion from "../../Common/Accordion";
import { Grid, IconButton, Typography } from "@material-ui/core";
import SmallTable from "../../Common/SmallTable";
import { semesterStatusLabel } from "../../../utils/cellRenderer/SemesterStatusCellRenderer";
import {
    createAddressStringFromObject,
    getSemesterStatusOptionsLabelByValue,
    getTrainingFormLabelByValue, isKep,
    translateGender,
} from "../../../utils/AppConst";
import { getFinancingFormLabelByValue } from "../../Administration/Forms/Enrollment/options";
import { membershipStatusLabel } from "../../../utils/cellRenderer/MemberShipStatusCellRenderer";
import enrollmentActions from "../../../store/processes/newProcess/enrollment/actions";
import TypeCellRenderer from "./ProcessTypeCellRenderer";
import StatusCellRenderer from "./StatusCellRenderer";
import GetAppIcon from '@material-ui/icons/GetApp';
import instance from "../../../utils/API";
import { downloadFile } from "../../Administration/Forms/Enrollment/utils";

const Row = (data) => (
    <Grid
        item
        container
        xs={12}
        justify="space-between"
        style={{
            borderBottom: "1px solid #ddd",
        }}
    >
        <Typography variant="h6">{data.label}:</Typography>
        <Typography variant="h6">{data.value || "-"}</Typography>
    </Grid>
);

function StudentsProfile(props) {
    const { id } = useParams();
    const data = props.form;
    const { studentOngoing, studentDone } = props;

    const header = {
        title: "Hallgatói profil",
        breadcrumbs: {
            masterData: "Törzsadatok",
            "/students": "Hallgatók",
            studentProfile: "Hallgatói profil",
            fullName: data?.user?.fullName ?? "",
        },
    };

    useEffect(() => {
        props.loadingOn();
        props.getStudentOngoing(id);
        props.getStudentDone(id);
        props.get(id).finally(() => props.loadingOff());
    }, []);

    let actualUniversityStudiesColumnsCurrent = actualUniversityStudiesColumns;
    if (isKep()) {
        actualUniversityStudiesColumnsCurrent = actualUniversityStudiesColumnsCurrent.filter(field => field.value !== "neptun" );
    } else {
        actualUniversityStudiesColumnsCurrent = actualUniversityStudiesColumnsCurrent.filter(field => field.value !== "pedigreeNumber");
    }

    return (
        <PageTemplate header={header}>
            <Grid item xs={12} sm={10}>
                <Accordion title="Hallgatói profil" fullWidth expanded>
                    <Grid container spacing={2}>
                        {Children.toArray(keys(data).map((key) => Row(key)))}
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    marginTop: "16px",
                                }}
                            >
                                Aktuális egyetemi tanulmányok: 
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <SmallTable
                                columns={actualUniversityStudiesColumnsCurrent}
                                rows={actualUniversityStudiesRow(data)}
                                minWidth="70px"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    marginTop: "16px",
                                }}
                            >
                                Szemeszteradatok:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <SmallTable
                                columns={semesterColumns}
                                rows={[semesterRow(data)]}
                                minWidth="70px"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    marginTop: "16px",
                                }}
                            >
                                Aktív folyamatok:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <SmallTable
                                columns={processColumns}
                                rows={processRow(
                                    studentOngoing["hydra:member"]
                                )}
                                minWidth="70px"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                style={{
                                    marginTop: "16px",
                                }}
                            >
                                Lezárt folyamatok:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <SmallTable
                                columns={processColumns}
                                rows={processRow(studentDone["hydra:member"])}
                                minWidth="70px"
                            />
                        </Grid>
                    </Grid>
                </Accordion>
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { form } = state.students;
    const studentOngoing = state.enrollment.studentOngoing;
    const studentDone = state.enrollment.studentDone;
    return { form, studentOngoing, studentDone };
}

const actionCreators = {
    get: studentsActions.get,
    getStudentOngoing: enrollmentActions.getStudentOngoing,
    getStudentDone: enrollmentActions.getStudentDone,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
};

const keys = (form) => {
    let fields = [
        {
            key: "name",
            label: "Név",
            value:
                (form?.user?.lastName || "") +
                " " +
                (form?.user?.firstName || ""),
        },
        { key: "email", label: "E-mail", value: form?.user?.email || "" },
        { key: "omId", label: "OM azonosító", value: form?.omId || "" },
        {
            key: "phoneNumber",
            label: "Telefonszám",
            value: form?.phoneNumber || "",
        },
        {
            key: "identificationNumberCnp",
            label: "Személyi szám (CNP)",
            value: form?.identificationNumberCnp || "",
        },
        {
            key: "taxNumber",
            label: "Adóazonosító jel",
            value: form?.taxNumber || "",
        },
        { key: "gender", label: "Nem", value: translateGender(form?.gender) },
        {
            key: "address",
            label: "Cím",
            value: createAddressStringFromObject(form?.address || ""),
        },
        {
            key: "idCardNumber",
            label: "SZIG szám",
            value: form?.idCardNumber || "",
        },
        {
            key: "bankName",
            label: "Pénzintézet neve",
            value: form?.bankName || "",
        },
        {
            key: "bankAccountNumber",
            label: "Bankszámlaszám",
            value: form?.bankAccountNumber || "",
        },
        {
            key: "birthPlace",
            label: "Születési hely",
            value: form?.birthPlace || "",
        },
        {
            key: "birthDate",
            label: "Születési idő",
            value: form?.birthDate
                ? new Date(form?.birthDate || "").toLocaleDateString("hu-HU")
                : null,
        },
        {
            key: "motherName",
            label: "Anyja neve",
            value:
                `${form?.motherLastName || ""} ${
                    form?.motherFirstName || ""
                }` === " "
                    ? null
                    : `${form?.motherLastName || ""} ${
                          form?.motherFirstName || ""
                      }`,
        },
    ];

    /**
     * If form.foreignUniversity is set, then we add the foreign university field after the university field
     * the field is: {
            key: "foreignUniversity",
            label: "Külföldi egyetem neve",
            value: form?.foreignUniversity || "",
        },

        But if it's already added to the array, we don't add it
     */
    if (
        form?.foreignUniversity &&
        !fields.find((f) => f.key === "foreignUniversity")
    ) {
        fields.splice(13, 0, {
            key: "foreignUniversity",
            label: "Külföldi egyetem neve",
            value: form?.foreignUniversity || "",
        });
    }

    return fields;
};

const handleDownload = (studentId, universityStudy) => {
    instance.get(`/students/${studentId}/university-study/${universityStudy.id}/download-file`, {responseType: 'blob'})
        .then((response) => {
            downloadFile(response.data, universityStudy.fileName);
        });
}

const actualUniversityStudiesColumns = [
    { value: "university", label: "Egyetem" },
    { value: "major", label: "Szak" },
    { value: "trainingForm", label: "Képzés formája" },
    { value: "financeForm", label: "Finanszírozás formája" },
    { value: "semesterStatus", label: "Hallgatói jogviszony" },
    { value: "neptun", label: "Neptun kód" },
    { value: 'pedigreeNumber', label: 'Törzskönyvi szám' },
    { value: "operations", label: "Feltöltött dokumentum" },
];

const actualUniversityStudiesRow = (data) => {
    if (data?.actualUniversityStudies != undefined) {
        return data?.actualUniversityStudies.map((element) => ({
            university: element.university.name,
            major: element.major,
            trainingForm: getTrainingFormLabelByValue(element.trainingForm),
            financeForm: getFinancingFormLabelByValue(element.financeForm),
            semesterStatus: getSemesterStatusOptionsLabelByValue(element.semesterStatus),
            neptun: element.neptun,
            pedigreeNumber: element.pedigreeNumber,
            operations: (
                element.fileName && element.filePath && (
                    <IconButton color='default' onClick={() => handleDownload(data.id, element)}>
                        <GetAppIcon />
                    </IconButton>
                )
            )
        }));
    }
    return [];
};

const semesterColumns = [
    { value: "currentMembershipStatus", label: "Aktuális státusz" },
    { value: "scholarshipStatus", label: "Hallgatói jogviszony" },
    { value: "currentTrainingLevel", label: "Képzési szint" },
    { value: "currentTrainingProgram", label: "Képzési program" },
    { value: "currentCollegeYear", label: "Évfolyam" },
    { value: "campus", label: "Campus" },
    { value: "building", label: "Épület" },
    { value: "floor", label: "Szint" },
    { value: "room", label: "Szoba" },
];

const semesterRow = (data) => {
    return {
        currentMembershipStatus: membershipStatusLabel(
            data?.currentMembershipStatus || ""
        ),
        scholarshipStatus: semesterStatusLabel(data?.scholarshipStatus || ""),
        currentTrainingLevel: data?.currentTrainingLevel?.name || "",
        currentTrainingProgram: data?.currentTrainingProgram?.name || "",
        currentCollegeYear: data?.currentCollegeYear?.name || "",
        campus:
            data?.currentRoom?.floor?.building?.campus?.name ||
            data.user?.campus?.name ||
            "",
        building: data?.currentRoom?.floor?.building?.name || "",
        floor: data?.currentRoom?.floor?.name || "",
        room: data?.currentRoom?.name || "",
        semesterStatus: data?.semesterStatus?.name || "",
    };
};

const processColumns = [
    { value: "displayId", label: "Sorszám" },
    { value: "type", label: "Típus" },
    { value: "status", label: "Folyamatstátusz" },
    { value: "campus", label: "Campus" },
    { value: "semester", label: "Szemeszter" },
];

const processRow = (data) =>
    data
        ?.map((element) => ({
            displayId: element?.displayId || "",
            type: TypeCellRenderer(element?.type || ""),
            status: StatusCellRenderer(element?.status || ""),
            campus: element?.campus?.name || "",
            semester: element?.semester?.name || "",
        }))
        .sort((a, b) => (a.semester > b.semester ? -1 : 1));

export default connect(mapState, actionCreators)(StudentsProfile);
